<template>
  <div class="py-1">
    <b-container>
      <h2 class="font-weight-bolder text-colorBlue pt-5 text-center">
        Meet Our Expert Healthcare Team
      </h2>

      <!-- Filters Below  -->
      <b-row class="mt-5 px-5" align-v="center">
        <b-col md="3">
          <b-form-group label="Search Type" label-for="searchType">
            <v-select
              id="searchType"
              v-model="searchType"
              :options="searchTypes"
              placeholder="Search Type"
              label="name"
            />
          </b-form-group>
        </b-col>
        <b-col md="7">
          <div v-if="searchType">
            <b-form-group
              label="Name"
              label-for="name"
              class="w-50"
              v-if="searchType.value === 1"
            >
              <b-input-group>
                <template #prepend> </template>
              </b-input-group>
              <b-form-input
                id="name"
                v-model="name"
                placeholder="Doctor Name"
              />
            </b-form-group>
            <b-form-group
              label="Hospital"
              label-for="hospital"
              class="w-50"
              v-if="searchType.value === 2"
            >
              <v-select
                id="hospital"
                v-model="selectedHospital"
                :options="hospitals"
                placeholder="Select Hospital"
                label="name"
              />
            </b-form-group>

            <b-form-group
              label="Speciality"
              label-for="department"
              class="w-50"
              v-if="searchType.value === 3"
            >
              <v-select
                id="department"
                v-model="selectedDepartment"
                :options="departments"
                placeholder="Select Speciality"
                label="name"
              />
            </b-form-group>
            <!-- <b-form-group
              label="Procedure"
              label-for="procedure"
              class="w-50"
              v-if="searchType.value === 4"
            >
              <v-select
                id="procedure"
                v-model="selectedProcedure"
                :options="procedures"
                placeholder="Select Procedure"
                label="name"
              />
            </b-form-group> -->
          </div>
        </b-col>

        <b-col md="2" class="text-right">
          <b-button variant="primary" pill @click="search">
            <feather-icon icon="SearchIcon" class="mr-50" />
            <span class="align-middle">Search</span>
          </b-button>
        </b-col>
      </b-row>
      <!-- Filters Above -->

      <b-row class="mt-5 px-5">
        <b-col
          md="3"
          class="d-flex align-items-stretch justify-content-center mb-1"
          style="position: relative"
          v-for="(doctor, index) in displayedDoctors"
          :key="index.id"
        >
          <div class="doctor-card d-flex flex-column">
            <div class="doctor-image-container">
              <!-- Doctor Image -->
              <img
                :src="doctor.profile_image"
                alt="Doctor Image"
                style="width: 100%; height: 300px"
              />
            </div>

            <div
              class="bg-colorBlue py-1 px-2"
              style="
                border-bottom-left-radius: 10px;
                border-bottom-right-radius: 10px;
                height: auto;
              "
            >
              <h4 class="font-weight-bolder text-white multi-line-truncate">
                {{ doctor.name }}
              </h4>
              <h5 class="text-white font-weight-normal multi-line-truncate">
                {{ doctor.intro }}
              </h5>
              <b-button
                @click="openProfileModal(doctor)"
                class="mt-1"
                variant="light"
                >View Details</b-button
              >
            </div>
          </div>
        </b-col>
      </b-row>

      <div class="text-center mt-3 mb-3">
        <b-button
          v-if="currentPage < totalPages"
          @click="showMore"
          class="mr-2"
          variant="colorGreen"
          size="md"
        >
          Show More
        </b-button>
        <b-button
          v-if="currentPage > 1"
          @click="showLess"
          variant="colorBlue"
          size="md"
        >
          Show Less</b-button
        >
      </div>
    </b-container>

    <doctor-view-modal
      :doctor="selectedDoctor"
      @modalClosed="closeProfileModal"
      :key="`view-${DoctorViewModal}`"
    />
  </div>
</template>

<script>
import DoctorViewModal from "@/components/doctorProfile/DoctorProfileModal.vue";
import { mapActions } from "vuex";
import util from "@/util.js";

export default {
  components: {
    DoctorViewModal,
  },
  props: {
    id: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      doctors: [],
      displayedDoctors: [],
      showModal: false,
      selectedDoctor: null,
      DoctorViewModal: 0,
      currentPage: 1,
      totalPages: 0,
      itemsPerPage: 5,
      searchTypes: [
        { value: 1, name: "Name" },
        { value: 2, name: "Hospital" },
        { value: 3, name: "Speciality" },
      ],
      searchType: null,
      name: "",
      selectedHospital: null,
      hospitals: [],
      selectedDepartment: null,
      departments: [],
      selectedProcedure: null,
      procedures: [],
    };
  },
  mixins: [util],
  created() {
    this.searchType = this.searchTypes[0];
  },
  methods: {
    ...mapActions({
      getDoctors: "appData/getDoctorsPublic2",
      getHospitalsPublic: "appData/getHospitalsPublic",
      getProceduresPublic: "appData/getProceduresPublicNav",
      getDepartmentsPublic: "appData/getDepartmentsPublicNav",
    }),
    openProfileModal(doctor) {
      this.DoctorViewModal += 1;
      this.selectedDoctor = doctor;
      this.$nextTick(() => {
        this.$bvModal.show("doctor-view-modal");
      });
    },

    async search() {
      if (this.searchType) {
        switch (this.searchType.value) {
          case 1:
            this.selectedHospital = null;
            this.selectedDepartment = null;
            this.selectedProcedure = null;

            break;
          case 2:
            this.name = "";
            this.selectedDepartment = null;
            this.selectedProcedure = null;
            break;
          case 3:
            this.name = "";
            this.selectedHospital = null;
            this.selectedProcedure = null;
            break;
          case 4:
            this.name = "";
            this.selectedHospital = null;
            this.selectedDepartment = null;
            break;
        }
      } else {
        this.name = "";
        this.selectedHospital = null;
        this.selectedDepartment = null;
        this.selectedProcedure = null;
      }
      this.currentPage = 1;
      await this.fetchData();
    },

    async fetchHospitals() {
      try {
        const res = await this.getHospitalsPublic();
        this.hospitals = res.data;
      } catch (error) {
        console.error("Error fetching hospitals:", error);
      }
    },
    async fetchDepartments() {
      try {
        const res = await this.getDepartmentsPublic();
        this.departments = res.data;
      } catch (error) {
        console.error("Error fetching Departments:", error);
      }
    },
    async fetchProcedures() {
      try {
        const res = await this.getProceduresPublic();
        this.procedures = res.data;
      } catch (error) {}
    },
    async fetchData() {
      try {
        // Only reset data if fetching the first page
        if (this.currentPage === 1) {
          this.doctors = [];
          this.displayedDoctors = [];
        }

        const queryParams = {
          id: this.id || null,
          full_name: this.name || null,
          department: this.selectedDepartment
            ? this.selectedDepartment.id
            : null,
          hospital: this.selectedHospital ? this.selectedHospital.id : null,
          procedure: this.selectedProcedure ? this.selectedProcedure.id : null,
          pageNumber: this.currentPage,
        };

        const filteredParams = Object.fromEntries(
          Object.entries(queryParams).filter(([_, v]) => v != null)
        );

        const response = await this.getDoctors(filteredParams);

        // Update total pages
        this.totalPages = response.data.total_pages;

        // Append new doctors to the existing array
        if (this.currentPage === 1) {
          this.doctors = response.data.results;
        } else {
          this.doctors = [...this.doctors, ...response.data.results];
        }

        // Update displayedDoctors to show all doctors for the current page range
        this.displayedDoctors = this.doctors.slice(
          0,
          this.currentPage * this.itemsPerPage
        );
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },

    showMore() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
        this.fetchData(); // Fetch next page of data
      }
    },

    showLess() {
      if (this.currentPage > 1) {
        this.currentPage--;
        // Update displayedDoctors without fetching new data
        this.displayedDoctors = this.doctors.slice(
          0,
          this.currentPage * this.itemsPerPage
        );
      }
    },
    closeProfileModal() {
      this.selectedDoctor = null;
    },
  },
  async mounted() {
    this.currentPage = 1;
    await this.fetchData();
    await this.fetchHospitals();
    await this.fetchDepartments();
    await this.fetchProcedures();
  },
  // watch: {
  //   name: {
  //     handler() {
  //       this.currentPage = 1;
  //       this.fetchData();
  //     },
  //     immediate: true,
  //   },
  //   selectedHospital: {
  //     handler() {
  //       this.currentPage = 1;
  //       this.fetchData();
  //     },
  //     immediate: true,
  //   },
  //   selectedDepartment: {
  //     handler() {
  //       this.currentPage = 1;
  //       this.fetchData();
  //     },
  //     immediate: true,
  //   },
  // },
};
</script>

<style>
.multi-line-truncate {
  display: -webkit-box;
  -webkit-line-clamp: 1; /* Number of lines to show */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.5; /* Adjust line height if needed */
  max-height: calc(1.5em * 3); /* 1.5em * number of lines to show */
}
.doctor-card {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 300px; /* Adjust max-width as needed */
  border: 1px solid #ccc;
  border-radius: 10px;
  overflow: hidden;
}

.doctor-image-container {
  flex: 1;
}
</style>
